import React from 'react'
import { YellowButton, BlackButton } from '../Button'

const CallToAction = () => (
  <div className="bg-gray-50">
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        Ready to book?
        <br />
        <span className="text-yellow-300">Get in touch with us today.</span>
      </h2>
      <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
        <div className="inline-flex rounded-md shadow">
          <YellowButton to="/book-now">Book Now</YellowButton>
        </div>
        <div className="ml-3 inline-flex rounded-md shadow">
          <BlackButton to="/about" title="">About Us</BlackButton>
        </div>
      </div>
    </div>
  </div>
)

export default CallToAction