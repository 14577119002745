import React, { useState } from 'react'
import MainNav from './MainNav'
import MobileNav from './MobileNav'

const Header = () => {
  const [open, setOpen] = useState(false)

  return (
    <div className="relative bg-black">
      <div className="max-w-screen-xl mx-auto">
        <div className="relative z-10 lg:w-full">
          <MainNav handleChange={() => setOpen(true)} />
          <MobileNav open={open} handleChange={() => setOpen(false)} />
        </div>
      </div>
    </div>
  )
}

export default Header