import React from 'react'
import { Link } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

import Logo from '../../../assets/images/white-logo-on-black.svg'

const MobileNav = ({ open, handleChange }) => (
  <CSSTransition
    in={open}
    classNames={{
      enter: 'opacity-0',
      enterActive: 'opacity-100 duration-150 ease-out',
      exitActive: 'opacity-0 duration-100 ease-in',
     }}
     timeout={{
      enter: 150,
      exit: 100,
     }}
     unmountOnExit
  >
    <div className={`absolute top-0 inset-x-0 p-2 transition transform origin-top-right xl:hidden`}>
      <div className="rounded-lg shadow-md">
        <div className="rounded-lg bg-black shadow-xs overflow-hidden">
          <div className="px-5 pt-4 flex items-center justify-between">
            <div>
              <img className="h-3 sm:h-4 w-auto" src={Logo} alt="Chauffeur Me - Executive, Professional and Luxury Chauffeur Services" />
            </div>
            <div className="-mr-2">
              <button onClick={handleChange} aria-label="Close Menu" type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-900 hover:bg-yellow-300 focus:outline-none  focus:bg-yellow-200 focus:text-gray-900 transition duration-150 ease-in-out">
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div className="px-2 pt-2 pb-3">
            <Link to="/" className="block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Home</Link>
            <Link to="/services" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Services</Link>
            <Link to="/tours" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Tours</Link>
            <div className="pl-5">
              <Link to="/tours/giants-causeway-tour-from-belfast" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Giants Causeway Tour</Link>
              <Link to="/tours/carrick-a-rede-rope-bridge-tour-from-belfast" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Carrick-a-Rede Rope Bridge Tour</Link>
              <Link to="/tours/game-of-thrones-tour-from-belfast" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Game of Thrones Tour</Link>
              <Link to="/tours/belfast-city-tour-from-belfast" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Belfast City Tour</Link>
              <Link to="/tours/belfast-political-tour-from-belfast" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Belfast Political Tour</Link>
              <Link to="/tours/bespoke-tour-from-belfast" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Bespoke Tours</Link>
            </div>
            <Link to="/about" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">About</Link>
            <Link to="/our-fleet" className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-white hover:text-gray-400 focus:outline-none focus:text-yellow-200 transition duration-150 ease-in-out">Our Fleet</Link>
          </div>
          <div>
            <Link to="/book-now" className="block w-full px-5 py-3 text-center font-medium text-gray-900 bg-yellow-300 hover:bg-yellow-200 focus:outline-none focus:bg-yellow-200 transition duration-150 ease-in-out">
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  </CSSTransition>
)

export default MobileNav