import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'

import Logo from '../../../assets/images/white-logo-on-black.svg'
import { YellowButton } from '../Button'
import Transition from '../Transition'
import { ChevronDownIcon } from '../Icons'

const ToursMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dd = useRef(null)

  useEffect(() => {
    const handleClickOutside = e => {
      if(dd?.current?.contains(e.target)) {
        return
      }
      setIsOpen(false)
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="px-4 relative inline">
      <div className="-mr-2 inline-flex items-center">
        <Link to="/tours" className="text-sm uppercase font-normal tracking-wider text-gray-100 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition duration-150 ease-in-out">
          Tours
        </Link>
        <button ref={dd} type="button" onClick={()=>setIsOpen(!isOpen)} className="inline-flex py-1 px-2 cursor-pointer focus:outline-none focus:text-gray-300">
          <ChevronDownIcon className="text-white h-4 w-4" />
        </button>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="absolute pt-3 left-1/2 transform -translate-x-1/2 px-2 w-56 max-w-xs sm:px-0">
          <div className="bg-white rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs overflow-hidden py-2">
              <Link to="/tours/giants-causeway-tour-from-belfast" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">Giants Causeway Tour</Link>
              <Link to="/tours/carrick-a-rede-rope-bridge-tour-from-belfast" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">Carrick-a-Rede Rope Bridge Tour</Link>
              <Link to="/tours/game-of-thrones-tour-from-belfast" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">Game of Thrones Tour</Link>
              <Link to="/tours/belfast-city-tour-from-belfast" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">Belfast City Tour</Link>
              <Link to="/tours/belfast-political-tour-from-belfast" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">Belfast Political Tour</Link>
              <Link to="/tours/bespoke-tour-from-belfast" className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900">Bespoke Tours</Link>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}
const MainNav = ({ handleChange }) => (
  <div className="py-6 px-4 sm:px-6 lg:px-8">
    <nav className="relative flex items-center justify-between sm:h-10">
      <div className="flex items-center flex-grow flex-shrink-0 xl:flex-grow-0">
        <div className="flex items-center justify-between w-full">
          <Link to="/">
            <img className="h-3 sm:h-4 w-auto ml-3 sm:ml-1 lg:-ml-1 xl:ml-0" src={Logo} alt="Chauffeur Me - Executive, Professional and Luxury Chauffeur Services" />
          </Link>
          <div className="mr-1 sm:-mr-1 lg:-mr-2 flex items-center xl:hidden">
            <button onClick={handleChange} aria-label="Open Menu" type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-900 hover:bg-yellow-300 focus:outline-none focus:bg-yellow-200 focus:text-gray-900 transition duration-150 ease-in-out">
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="hidden xl:block xl:ml-10">
        <Link to="/" className="px-4 text-sm uppercase font-normal tracking-wider text-gray-100 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition duration-150 ease-in-out">Home</Link>
        <Link to="/services" className="px-4 text-sm uppercase font-normal tracking-wider text-gray-100 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition duration-150 ease-in-out">Services</Link>
        <ToursMenu />
        <Link to="/about" className="px-4 text-sm uppercase font-normal tracking-wider text-gray-100 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition duration-150 ease-in-out">About</Link>
        <Link to="/our-fleet" className="px-4 text-sm uppercase font-normal tracking-wider text-gray-100 hover:text-gray-300 focus:outline-none focus:text-gray-300 transition duration-150 ease-in-out">Our Fleet</Link>
        <YellowButton to="/book-now" className="ml-8">Book Now</YellowButton>
      </div>
    </nav>
  </div>
)

export default MainNav