import React from "react"

import Header from "../components/Global/Header"
import CallToAction from "../components/Global/CallToAction"
import Footer from "../components/Global/Footer"

const Layout = ({ children }) => (
  <>
    <Header />
      {children}
    <CallToAction />
    <Footer />
  </>
)

export default Layout
