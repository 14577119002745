import React from 'react'
import Logo from '../../../assets/images/white-logo-on-black.svg'
import { Link } from 'gatsby'
import InlineLink from '../Link/InlineLink'

const Footer = () => (
  <div className="bg-black">
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:pt-16 lg:pb-4 lg:px-8">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        <div className="xl:col-span-1">
          <img className="h-6" src={Logo} alt="Chauffeur Me - Executive, Professional and Luxury Chauffeur Services" />
          <p className="mt-8 text-white text-base leading-6">
            Executive, professional and luxury chauffeur service based in Belfast, Northern Ireland.
          </p>
          <div className="mt-8 flex">
            <a href="https://www.facebook.com/chauffeurmeltd" className="text-white hover:text-gray-300">
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd"/>
              </svg>
            </a>
          </div>
        </div>
        <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">
                Pages
              </h4>
              <ul className="mt-4">
                <li>
                  <Link to="/" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Home
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/about" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    About
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/our-fleet" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Our Fleet
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/book-now" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Book Now
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">
                Services
              </h4>
              <ul className="mt-4">
                <li>
                  <Link to="/services/chauffeur-driven-golf-trips" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Golf Trips
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/services/executive-airport-transfers" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Airport Transfers
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/services/luxury-cruise-ship-excursions" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Cruise Ship Excursions
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/tours" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Private Tours
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/services/corporate-travel" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Corporate Travel
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/services/professional-wedding-chauffeur" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Weddings
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">
                Tours
              </h4>
              <ul className="mt-4">
                <li>
                  <Link to="/tours/giants-causeway-tour" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Giants Causeway Tour
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/tours/carrick-a-rede-rope-bridge-tour" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Carrick-a-Rede Rope Bridge Tour
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/tours/game-of-thrones-tour" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Game of Thrones Tour
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/tours/belfast-city-tour" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Belfast City Tour
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/tours/belfast-political-tour" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Belfast Political Tour
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/tours/bespoke-tour" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Bespoke Tours
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-white uppercase">
                Legal
              </h4>
              <ul className="mt-4">
                <li>
                  <Link to="/legal/privacy-policy" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Privacy
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="/legal/terms-and-conditions" className="text-base leading-6 text-gray-300 hover:text-gray-400">
                    Terms
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 border-t border-white pt-4 lg:flex lg:justify-between">
        <p className="text-sm leading-6 text-white opacity-50">
          &copy; 2020 Chauffeur Me. All rights reserved.
        </p>
        <p className="text-sm leading-6">
          <InlineLink href="https://www.amigostudios.co">Web Design &amp; Development by Amigo Studios</InlineLink>
        </p>
      </div>
    </div>
  </div>
)

export default Footer