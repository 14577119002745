import React from 'react'
import { Link } from 'gatsby'

const InlineLink = ({ children, to, href, ...props }) => {
  if (to) {
    return <Link to={to} className="text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50" {...props}>{children}</Link>
  }
  if (href) {
    return <a href={href} className="text-yellow-400 underline hover:text-yellow-300 hover:no-underline ease-in-out transition-all duration-50" {...props}>{children}</a>
  }
  return <>{children}</>
}

export default InlineLink